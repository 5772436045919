import React, { useState, useEffect } from 'react';
import { MdBatteryCharging80, MdVolumeDownAlt, MdNetworkWifi } from "react-icons/md";

function NavBar({ ShowCalendarMenu,setShowCalendarMenu , ShowSystemMenu , setSystemMenu }) {
  const [dateTime, setDateTime] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => clearInterval(interval); 
  }, []);

  const formattedDateTime = dateTime.toLocaleString('en-US', {
    day: '2-digit', 
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  return (
    <div 
      style={{ 
        backgroundColor: '#111111', 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'space-between', 
        position: 'fixed',   
        top: 0,          
        width: '100%',  
        zIndex: 1000,     
      }} 
      className='navbar py-1 px-4'>
      <div className='text-left'>
        <img 
          src="https://www.geekandjob.com/uploads/wiki/68f0a2a8c5ce4561c1f04b5afe3949383fe30ca2.png" 
          alt="Logo" 
          style={{ height: '19px' }} />
      </div>

      <div  className='text-center px-8 hover:bg-black hover:opacity-80'  onClick={ () => setShowCalendarMenu(!ShowCalendarMenu) }>
        <p className='text-white hover:text-opacity-100' style={{ fontSize: '14px', margin: 0 }} >  {formattedDateTime}  </p>
      </div>

      <div className='text-right pl-2  hover:bg-black hover:opacity-80' style={{ display: 'flex', alignItems: 'center' }}   onClick={ () => setSystemMenu(!ShowSystemMenu) }>
        <MdNetworkWifi color='white' size={17} style={{ marginRight: '20px' }} />
        <MdVolumeDownAlt color='white' size={20} style={{ marginRight: '15px' }} />
        <MdBatteryCharging80 color='white' size={20} />
      </div>


    </div>
  );
}

export default NavBar;
