import './App.css';
import React from 'react';

import { Route, Routes } from 'react-router-dom';

import HomePage from './pages/Index';
import ThreeJs from './pages/ThreeJs';

function App() {
  return (
    <div className="App" >
   
   <Routes>

         <Route path="/ThreeJs" element={<ThreeJs  />} />
        <Route path="/" element={<HomePage />} /> 

        
      </Routes>
   
    </div>
  );
}

export default App;
