import { useLoader, useFrame } from "@react-three/fiber";
import React, { useMemo, useEffect } from "react";
import * as THREE from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { AxesHelper, GridHelper, PlaneHelper } from 'three';

const Nature = () => {
  const [FTree, Car, SnowMan, Campfire, HayBale] = useLoader(FBXLoader, [
    "./textures/nature/FTree1.fbx",
    "./textures/nature/Car.fbx",
    "./textures/nature/SnowMan.fbx",
    "./textures/nature/Campfire.fbx",
    "./textures/nature/HayBale.fbx",
  ]);
  
  const setupObject = (obj, scale) => {
    obj.scale.setScalar(scale);
    obj.traverse((o) => {
      o.castShadow = true;
      o.receiveShadow = true;
    });
  };   
  setupObject(FTree, 0.1);
  setupObject(Car, 0.085);
  setupObject(SnowMan, 0.22);
  setupObject(Campfire, 0.04);
  setupObject(HayBale, 0.08);

 
  const campfireMixer = useMemo(() => new THREE.AnimationMixer(Campfire), [Campfire]);

  useEffect(() => {
    if (Campfire.animations && Campfire.animations.length > 0) {
      const action = campfireMixer.clipAction(Campfire.animations[0]);
      action.play();
    } else {
      console.warn("No animations found in the Campfire object.");
    }
  }, [campfireMixer, Campfire]);

  useFrame((state, delta) => {
    campfireMixer.update(delta);
  });

  const objectsWithPositions = [
    { object: Car, position: [1.99, -3, 93.26] },
    { object: SnowMan, position: [-30.85, -1, 73.78] },
    { object: Campfire, position: [-91.18524638909146, 0, 67.98849802055226] },

    { object: HayBale, position: [-115.98497039994035, 0, 70.5635400240037]  },  

    { object: HayBale, position: [-115.98497039994035, 0, 53.5635400240037] },


    { object: HayBale, position: [-98.98497039994035, 0, 87.5635400240037], rotation: [0, Math.PI / 2, 0] },   
    { object: HayBale, position: [-80.98497039994035, 0, 87.5635400240037], rotation: [0, Math.PI / 2, 0] }, 
    { object: FTree, position: [-168.54, 0, 43.07] },
    { object: FTree, position: [-171.67, 0, 154.22] },
    { object: FTree, position: [-102.68, 0, 14.97] },
    { object: FTree, position: [-24.9, 0, 197.53] },
    { object: FTree, position: [-67.51, 0, 142.14] },
    { object: FTree, position: [-67.51, 0, 142.14] },
    { object: FTree, position: [121.14, 0, 27.27] },
    { object: FTree, position: [35.36, 0, 159.32] },
    { object: FTree, position: [82.93, 0, 101.58] },
    { object: FTree, position: [99.60, 0, -48.03] },
    { object: FTree, position: [-116.77, 0, -67.53] },
    { object: FTree, position: [38.71, 0, -148.85] },
  ];

  const objects = objectsWithPositions.map((item, index) => (
    <primitive
      key={index}
      object={item.object.clone()}
      position={item.position}
      rotation={item.rotation || [0, 0, 0]} 
    />
  ));

  const helpers = useMemo(() => {
    const axisHelper = new AxesHelper(50); 
    const gridHelper = new GridHelper(100, 10); 
    const planeHelper = new PlaneHelper(new THREE.Plane(new THREE.Vector3(0, 1, 0), 0), 0); 

    return [axisHelper, gridHelper, planeHelper];
  }, []);

  return (
    <group>
      {helpers.map((helper, index) => (
        <primitive  key={index} object={helper} />
      ))}
      {objects}
    </group>
  );
};

export default Nature;
