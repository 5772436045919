import React, { createContext, useContext, useState } from 'react';



const GamesContext = createContext();

export const GamesProvider = ({ children }) => {
  const [Games, setGames] = useState([
    {
      id: 1,
      name: "Disc Pool",
      imgUrl: "https://www.htmlgames.com/uploaded/game/thumb/discpool2player300200.webp",
      installed: true,
      GameUrl:'https://cdn.htmlgames.com/DiscPool2Player/',
      Type:"Classic",
    },
    {
      id: 2,
      name: "Ninja Breakout",
      imgUrl: "https://www.htmlgames.com/uploaded/game/thumb/ninjabreakout300200.webp",
      installed: true,
      GameUrl:'https://cdn.htmlgames.com/NinjaBreakout/',
      Type:"Classic",
    },  
    {
      id: 3,
      name: "Ms Tapman",
      imgUrl: "https://www.htmlgames.com/uploaded/game/thumb/mstapman300.webp",
      installed: true,
      GameUrl:'https://cdn.htmlgames.com/MsTapman/',
      Type:"Classic",
    },    {
      id: 4,
      name: "Ludo",
      imgUrl: "https://www.htmlgames.com/uploaded/game/thumb/ludo300200.webp",
      installed: true,
      GameUrl:'https://cdn.htmlgames.com/Ludo/',
      Type:"Classic",
    },   {
      id: 5,
      name: "MineSweeper",
      imgUrl: "https://www.htmlgames.com/uploaded/game/thumb/minesweeper300x200.webp",
      installed: true,
      GameUrl:'https://cdn.htmlgames.com/Minesweeper/',
      Type:"Classic",
    },  {
      id: 6,
      name: "Bingo",
      imgUrl: "https://www.htmlgames.com/uploaded/game/thumb/bingo300200.webp",
      installed: true,
      GameUrl:'https://cdn.htmlgames.com/Bingo/',
      Type:"Classic",
    },  {
      id: 7,
      name: "Vegas Poker",
      imgUrl: "https://www.htmlgames.com/uploaded/game/thumb/vegaspoker300200.webp",
      installed: true,
      GameUrl:'https://cdn.htmlgames.com/VegasPoker/',
      Type:"Classic",
    },  {
      id: 8,
      name: "President",
      imgUrl: "https://www.htmlgames.com/uploaded/game/thumb/president300200.webp",
      installed: true,
      GameUrl:'https://cdn.htmlgames.com/President/',
      Type:"Classic",
    }, {
      id: 9,
      name: "La Belle Lucie",
      imgUrl: "https://www.htmlgames.com/uploaded/game/thumb/labellelucie300200.webp",
      installed: true,
      GameUrl:'https://cdn.htmlgames.com/LaBelleLucie/',
      Type:"Classic",
    },{
      id: 10,
      name: "Kings Klondike",
      imgUrl: "https://www.htmlgames.com/uploaded/game/thumb/kingklondike300200.webp",
      installed: true,
      GameUrl:'https://cdn.htmlgames.com/KingsKlondike/',
      Type:"CardGames",
    },{
      id: 11,
      name: "Pyramid Klondike",
      imgUrl: "https://www.htmlgames.com/uploaded/game/thumb/pyramid-klondike300200.webp",
      installed: true,
      GameUrl:'https://cdn.htmlgames.com/PyramidKlondike/',
      Type:"CardGames",
    },{
      id: 12,
      name: "Gargantua Double Klondike",
      imgUrl: "https://www.htmlgames.com/uploaded/game/thumb/gargantuadoubleklondike300200.webp",
      installed: true,
      GameUrl:'https://cdn.htmlgames.com/GargantuaDoubleKlondike/',
      Type:"CardGames",
    },{
      id: 13,
      name: "Chocolate Factory",
      imgUrl: "https://www.htmlgames.com/uploaded/game/thumb/chocolatefactory300200.webp",
      installed: true,
      GameUrl:'https://cdn.htmlgames.com/ChocolateFactory/',
      Type:"MindGames",
    },{
      id: 14,
      name: "BBQ Roast",
      imgUrl: "https://www.htmlgames.com/uploaded/game/thumb/bbqroast300200.webp",
      installed: true,
      GameUrl:'',
      Type:"https://cdn.htmlgames.com/BBQRoast/",
    },{
      id: 15,
      name: "Airport Management",
      imgUrl: "https://www.htmlgames.com/uploaded/game/thumb/airportmanagement3300200.webp",
      installed: true,
      GameUrl:'https://cdn.htmlgames.com/AirportManagement3/',
      Type:"MindGames",
    },{
      id: 16,
      name: "2048 Billiards",
      imgUrl: "https://www.htmlgames.com/uploaded/game/thumb/2048billiards300200.webp",
      installed: true,
      GameUrl:'https://cdn.htmlgames.com/2048Billiards/',
      Type:"MindGames",
    },{
      id: 17,
      name: "Supermarket Numbers",
      imgUrl: "https://www.htmlgames.com/uploaded/game/thumb/supermarketnumbers300.webp",
      installed: true,
      GameUrl:'https://cdn.htmlgames.com/SupermarketNumbers/',
      Type:"MindGames",
    },{
      id: 18,
      name: "Construction Weights",
      imgUrl: "https://www.htmlgames.com/uploaded/game/thumb/constructionweights300.webp",
      installed: true,
      GameUrl:'https://cdn.htmlgames.com/ConstructionWeights/',
      Type:"MindGames",
    },{
      id: 18,
      name: "Daily Sudoku",
      imgUrl: "https://www.htmlgames.com/uploaded/game/thumb/dailysudoku300x200.webp",
      installed: true,
      GameUrl:'https://cdn.htmlgames.com/DailySudoku/',
      Type:"MindGames",
    },
   ]
  );

  return (
    <GamesContext.Provider value={[Games, setGames]}>
      {children}
    </GamesContext.Provider>
  );
};

export const useGames = () => useContext(GamesContext);
