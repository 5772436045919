import React, { useState } from 'react';
import NavBar from '../components/GUI/UI/Navs/NavBar';
import DesktopArea from '../components/GUI/Desktop/DesktopArea'; 

function Home() {
  const [ShowCalendarMenu, setShowCalendarMenu] = useState(false);
  const [ShowSystemMenu, setSystemMenu] = useState(false);


  return (
    <div 
      style={{ 
        backgroundImage: 'url(https://149366088.v2.pressablecdn.com/wp-content/uploads/2022/03/jammy-jellyfish-wallpaper.jpg)',
        backgroundSize: 'cover', 
        backgroundRepeat: 'no-repeat',  
        backgroundPosition: 'center', 
        height: '100vh',  
        width: '100vw', 
        display: 'flex', 
        flexDirection: 'column'  
      }}
    >
      <NavBar 
        ShowCalendarMenu={ShowCalendarMenu} 
        setShowCalendarMenu={setShowCalendarMenu} 
        ShowSystemMenu={ShowSystemMenu} 
        setSystemMenu={setSystemMenu} 
      />

      <div style={{ display: 'flex', flexGrow: 1 }}  >
        <DesktopArea 
          ShowCalendarMenu={ShowCalendarMenu}  
          setShowCalendarMenu={setShowCalendarMenu}  
          ShowSystemMenu={ShowSystemMenu} 
          setSystemMenu={setSystemMenu} 
        />
      </div>
    </div>   
  );
}

export default Home;
