import React  from 'react';
import { MdBatteryCharging80, MdVolumeDownAlt, MdNetworkWifi } from "react-icons/md";


function Application({ imgUrl, isActive ,Size }) {
  return (
    <div
      style={{ 
        padding:'0.2em',
        borderRadius: '5px',
        position: 'relative', // Required for positioning the bottom bar
        transition: 'background-color 0.3s ease' // Smooth transition for background color
      }}
    >
       <img src={imgUrl} alt="App Icon" style={{ height: `${Size}px` }} />    
    </div>
  );
}
export default Application;