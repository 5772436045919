import React, { useState } from 'react';
 

function VsCode() {

  return (
      <iframe src="https://github1s.com/anisbenbechir1/React-Project/blob/main/src/App.js" frameBorder="0" title="VsCode" className="h-full w-full bg-ub-cool-grey">
      </iframe>
  );
}

export default VsCode;
