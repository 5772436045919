import React, { useLayoutEffect, useMemo, useRef } from "react";
import { createNoise2D } from 'simplex-noise';
import { useTexture } from "@react-three/drei";
import * as THREE from "three";

const Ground = () => {
  const simplex = useMemo(() => createNoise2D(), []);

  const terrain = useRef(null);

  const [texture] = useTexture(['./textures/nature/Ground3.png']); 

  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(40, 40); 

  useLayoutEffect(() => {
    let pos = terrain.current.getAttribute("position");
    let pa = pos.array;

    const hVerts = terrain.current.parameters.heightSegments + 1;
    const wVerts = terrain.current.parameters.widthSegments + 1;

    for (let j = 0; j < hVerts; j++) {
      for (let i = 0; i < wVerts; i++) {
        const ex = Math.random() * 1.3;
        pa[3 * (j * wVerts + i) + 2] =
          (simplex(i / 100, j / 100) +
            simplex((i + 200) / 50, j / 50) * Math.pow(ex, 1) +
            simplex((i + 400) / 25, j / 25) * Math.pow(ex, 2) +
            simplex((i + 600) / 12.5, j / 12.5) * Math.pow(ex, 3) +
            simplex((i + 800) / 6.25, j / 6.25) * Math.pow(ex, 4)) /
          2;
      }
    }

    pos.needsUpdate = true;

    terrain.current.computeVertexNormals();
  });

  return (
    <mesh position={[0, 0, 0]} receiveShadow rotation={[-Math.PI / 2, 0, 0]}>
      <planeGeometry
        attach="geometry"
        args={[1000, 1000, 250, 250]}
        ref={terrain}
      />
      <meshStandardMaterial
        attach="material"
        map={texture} 
      />
    </mesh>
  );
};

export default Ground;
