import React, { useState } from 'react';
import NavApplication from '../../Applications/NavApplication';
import { useApplications } from '../../../../Contexts/ApplicationsProvider';  

function LeftBar({ onAppClick , setShowUbuntuMenu , ShowUbuntuMenu }) {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [applications] = useApplications();  

  return (
    <div 
      style={{ 
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',  
        padding: '11px',
        position: 'fixed',
        left: 0,
        height: '100vh',
        width: 'auto',
        overflow: 'hidden'
      }}
    >
      <div>
        {applications.map((app, index) => (
          app.Pinned ? (
            <div 
              key={index}
              className='mb-3'
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              onClick={() => onAppClick(app.AppUID)} 
            >
              <NavApplication 
                imgUrl={app.imgUrl} 
                isActive={app.isActive} 
                Size={32}
                isHovered={hoveredIndex === index}
              />
            </div>
          ) : null
        ))}
      </div>

      <div 
        style={{
          marginTop: 'auto', 
          marginBottom: '1.6em', 
        }}
        onMouseEnter={() => setHoveredIndex(applications.length)} 
        onMouseLeave={() => setHoveredIndex(null)}
        onClick={() => {
          onAppClick('bottom'); 
          setShowUbuntuMenu(!ShowUbuntuMenu); 
         }}>
        <NavApplication 
          imgUrl={'https://i.ibb.co/ZVZx14V/dots-grid-svgrepo-com.png'} 
          isActive={false}  
          Size={32}
    
          isHovered={hoveredIndex === applications.length}
        />
      </div>
    </div>
  );
}

export default LeftBar;
