import React, { useState } from "react";
import "./Styles/style.css"; // Import the CSS file for custom styles
import { Badge, Tooltip } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import { Card } from "flowbite-react";
import { RxCross1 } from "react-icons/rx";  
import { useGames } from '../../../Contexts/GamesProvider';

function GamesLibrary({ isMobile, GameType }) {
  const [Games] = useGames();  
  const [IsGameRunning, setIsGameRunning] = useState(false);
  const [ActiveGame, setActiveGame] = useState('');

  // Filter games based on the provided GameType
  const filteredGames = Games.filter(game => game.Type === GameType);

  function StartGame(GameUrl) {
    setIsGameRunning(true);
    setActiveGame(GameUrl);
  }

  function CloseGame() {
    setIsGameRunning(false);
    setActiveGame('');
  }

  return (
    <>
      {!IsGameRunning ? (
        <div className="scroll-container">
          <div
            className="games-container"
            style={{
              display: 'grid',
              gridTemplateColumns: isMobile ? '1fr' : 'repeat(3, 1fr)', // 1 column on mobile, 3 columns on larger screens
              gap: '16px', // Adds spacing between cards
            }}
          >
            {filteredGames.map((game) => (
              <Card
                key={game.id}
                style={{
                  backgroundColor: '#181818a4',
                  border: 'none',
                }}
                onClick={() => StartGame(game.GameUrl)} // Use a function reference here
                imgAlt={game.name}
                imgSrc={game.imgUrl}
              >
                <div className="flex items-center justify-between">
                  <span className="text-xl font-bold">{game.name}</span>
                  {game.installed ? (
                    <Tooltip content="Installed">
                      <Badge icon={HiCheck} className="mt-1" />
                    </Tooltip>
                  ) : (
                    <Tooltip content="Not Installed">
                      <Badge style={{backgroundColor:'red', color:'white'}} icon={RxCross1} className="mt-1" />
                    </Tooltip>
                  )}
                </div>
              </Card>
            ))}
          </div>
          {isMobile ? (<div className="pb-32" />) : (<div className="pb-6" />)}
        </div>
      ) : (
        <div style={{ height: '100%', width: '100%' }}>
          <a onClick={CloseGame}>Close</a>
          <iframe
            src={ActiveGame}
            style={{ transform:isMobile ? 'rotate(90deg)' :  'rotate(0deg)',height: '100%',width: '100%'}}
            title="Active Game"
          />
        </div>
      )}
    </>
  );
}

export default GamesLibrary;
