import React from 'react';

function NavApplication({ imgUrl, isActive, Size, isHovered }) {
  return (
    <div
      style={{ 
        backgroundColor: isHovered || isActive ? 'rgba(255, 255, 255, 0.3)' : 'transparent', // Show background color only on hover or if active
        padding: '0.2em',
        borderRadius: '5px',
        position: 'relative', // Required for positioning the bottom bar
        transition: 'background-color 0.3s ease' // Smooth transition for background color
      }}
    >
      <img src={imgUrl} alt="App Icon" style={{ height: `${Size}px` }} />
      
      {(isHovered || isActive) && (
        <div style={{
          position: 'absolute',
          bottom: 0,
          left: 8,
          right: 8,
          height: '3px',
          backgroundColor: 'orange',
          borderRadius: '0 0 5px 5px'  
        }} />
      )}
    </div>
  );
}

export default NavApplication;
