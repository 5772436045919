import React, { useState, useEffect } from 'react';
import Application from '../Applications/Application';
import Window from './Window';
import LeftBar from '../UI/Navs/LeftBar';
import HomeMenu from '../UI/Menus/HomeMenu';
import CalendarMenu from '../../../components/GUI/UI/Menus/CalendarMenu';
import SystemMenu from '../../../components/GUI/UI/Menus/SystemMenu';


import { useApplications } from '../../../Contexts/ApplicationsProvider';

function DesktopArea({ShowCalendarMenu, setShowCalendarMenu,ShowSystemMenu, setSystemMenu}) {
  const [applications, setApplications] = useApplications();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [windows, setWindows] = useState({});
  const [zIndexStack, setZIndexStack] = useState([]); 
  const [ShowLeftBar, setShowLeftBar] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [searchTerm, setSearchTerm] = useState('');
  const [ShowUbuntuMenu, setShowUbuntuMenu] = useState(false);

  const filteredApplications = applications.filter((app) =>
    app.title.toLowerCase().includes(searchTerm.toLowerCase())
  );


 



  const CheckDevice = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    CheckDevice();
    handleAppClick('AboutnMe'); 
  }, []);

  const updateApplicationActiveState = (AppUID, isActive) => {
    if (isActive && isMobile) {
      setShowLeftBar(false);
    }
    setApplications((prevApplications) =>
      prevApplications.map((app) =>
        app.AppUID === AppUID ? { ...app, isActive } : app
      )
    );
  };

  const handleWindowPositionChange = (id, newPosition) => {
    setWindows((prevWindows) => ({
      ...prevWindows,
      [id]: newPosition,
    }));

    setZIndexStack((prevStack) => {
      const newStack = prevStack.filter(windowId => windowId !== id);
      newStack.push(id);
      return newStack;
    });
  };

  const handleWindowClose = (id, AppUID) => {
    updateApplicationActiveState(AppUID, false);
    setWindows((prevWindows) => {
      const newWindows = { ...prevWindows };
      delete newWindows[id];
      return newWindows;
    });

    setZIndexStack((prevStack) => prevStack.filter(windowId => windowId !== id));
  };

  const handleWindowMaximize = (id, newPosition) => {
    setShowLeftBar(!ShowLeftBar);
    setWindows((prevWindows) => ({
      ...prevWindows,
      [id]: newPosition,
    }));
  };

  const formatTitle = (title) => {
    const regex = /.{1,8}/g;
    return title.match(regex).map((part, index, array) => {
      if (index < array.length - 1 && part.match(/\w$/) && array[index + 1].match(/^\w/)) {
        return part + ' \n';
      }
      return part + '\n';
    }).join('');
  };

  const handleWindowClick = (id) => {
    setZIndexStack((prevStack) => {
      const newStack = prevStack.filter(windowId => windowId !== id);
      newStack.push(id);
      return newStack;
    });
  };

  const handleAppClick = (AppUID) => {
    const appIndex = applications.findIndex(app => app.AppUID === AppUID);
    if (appIndex === -1) return;

    const newWindowId = `window-${appIndex}`;
    updateApplicationActiveState(AppUID, true);

    setWindows((prevWindows) => ({
      ...prevWindows,
      [newWindowId]: { x: 300, y: 100 },
    }));

    handleWindowClick(newWindowId);
  };




  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        flex: 1,
        position: 'relative',
        overflow: 'hidden',
      }} className={`navbar py-6 px-4 ${!ShowUbuntuMenu ? ' text-left ' : ''} ${ShowLeftBar ? 'ml-12' : ''}`} >
      {ShowLeftBar ? (
        <LeftBar
          onAppClick={handleAppClick}
          setShowUbuntuMenu={setShowUbuntuMenu}
          ShowUbuntuMenu={ShowUbuntuMenu}
        />
      ) : null}

      {!ShowUbuntuMenu ? (
  <div>          
   <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            
            {ShowCalendarMenu ? <CalendarMenu    isMobile={isMobile} /> : (<></>)}
            {ShowSystemMenu ? <SystemMenu  isMobile={isMobile}  /> : (<></>)}



            
            {applications.map((app, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '11px',
                }}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                onClick={() => {
                  const newWindowId = `window-${index}`;
                  updateApplicationActiveState(app.AppUID, true);

                  setWindows((prevWindows) => ({
                    ...prevWindows,
                    [newWindowId]: { x: 100, y: 100 },
                  }));
                  handleWindowClick(newWindowId);
                }}
              >
                <div
                  style={{
                    backgroundColor: hoveredIndex === index ? 'rgba(255, 255, 255, 0.3)' : 'transparent',
                    padding: '0.2em',
                    borderRadius: '5px',
                    transition: 'background-color 0.3s ease',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Application imgUrl={app.imgUrl} isActive={app.isActive} Size={40} />
                  <h4
                    style={{
                      whiteSpace: 'pre-wrap',
                      fontSize: '14px',
                      color: 'white',
                      textAlign: 'center',
                      marginTop: '0.5em',
                    }}
                  >
                    {formatTitle(app.title)}
                  </h4>
                </div>
              </div>
            ))}
          </div>

          {Object.entries(windows).map(([id, position]) => {
            const appIndex = parseInt(id.replace('window-', ''));
            const app = applications[appIndex] || {};
            const zIndex = zIndexStack.indexOf(id) + 1 || 1;

            return (
              <Window
                key={id}
                title={app.title || 'Untitled'}
                initialPosition={position}
                onPositionChange={(newPosition) => handleWindowPositionChange(id, newPosition)}
                onClose={() => handleWindowClose(id, app.AppUID)}
                onMaximize={(newPosition) => handleWindowMaximize(id, newPosition)}
                zIndex={zIndex}
                onClick={() => handleWindowClick(id)}
                AppIcon={app.imgUrl || 'https://www.geekandjob.com/uploads/wiki/68f0a2a8c5ce4561c1f04b5afe3949383fe30ca2.png'}
                WindowHeight={app.WindowHeight || 50}
                WindowWidth={app.WindowWidth || 40}
                App={app.AppComponent}
                AppUID={app.AppUID || 0}
                setShowLeftBar={setShowLeftBar}
                Path={app.Path || '/'}
              />
            );
          })}
        </div>
      ) : (
        <HomeMenu
          setShowUbuntuMenu={setShowUbuntuMenu}
          ShowUbuntuMenu={ShowUbuntuMenu}
          updateApplicationActiveState={updateApplicationActiveState}
          handleWindowClick={handleWindowClick}
          filteredApplications={filteredApplications}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          windows={windows}
          setWindows={setWindows}
          formatTitle={formatTitle}
        />
      )}
    </div>
  );
}

export default DesktopArea;
