import React from 'react';
import './Styles/style.css'; 
import {educationData, BusinessAdministration , coursesAndTrainings}from '../../../Data/AboutMeData';

function EducationContent({ isMobile }) {
  return (
<div className="scroll-container"> 
            <h2 className='text-center'>
        <span style={{ color: 'white', fontWeight: '600', fontSize: '23px' }}>Business Administration</span>
      </h2>

      <div
        style={{
          marginLeft: isMobile ? '0%' : '10%',
          marginRight: isMobile ? '0%' : '10%',
        }}
        className='mt-5'
      >
        <ul>
          {BusinessAdministration.map(({ id, year, Subject, Professor, icon }) => (
            <li key={id} className='my-4'>
              <h1 >{icon} - <span style={{ color: 'white', fontWeight: '600', fontSize: '15px' }}>{Subject}</span> <small>(By {Professor})</small></h1>
            </li>
          ))}
        </ul>
      </div>
      <h2 className='text-center'>
        <span style={{ color: 'white', fontWeight: '600', fontSize: '23px' }}>Online Courses</span>
      </h2>

      <div
        style={{
          marginLeft: isMobile ? '0%' : '10%',
          marginRight: isMobile ? '0%' : '10%',
        }}
        className='mt-5'
      >
        <ul>
          {coursesAndTrainings.map(({ id, year, courseName, provider, icon }) => (
            <li key={id} className='my-4'>
              <h1 >{icon} - <span style={{ color: 'white', fontWeight: '600', fontSize: '15px' }}>{courseName}</span> ({provider})</h1>
            </li>
          ))}
        </ul>
      </div>

      <h2 className='text-center'>
        <span style={{ color: 'white', fontWeight: '600', fontSize: '23px' }}>Education</span>
      </h2>

      <div
        style={{
          marginLeft: isMobile ? '0%' : '10%',
          marginRight: isMobile ? '0%' : '10%',
        }}
        className='mt-5'
      >
        <ul>
          {educationData.map(({ id, year, institution, degree, icon }) => (
            <li key={id} className='my-4'>
              <h1>{icon} - {institution}</h1>
              <p style={{ fontSize: '16px' }}>{degree}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className={`mb-${isMobile ? '32' : '10'}`} />



    </div>
  );
}

export default EducationContent;
