// Handles commands input by the user
export const handleCommand =  (command, currentPath, setOutput, setCurrentPath, updateFileSystem, fileSystem , ip) => {
  const [cmd, ...args] = command.split(' ');

  switch (cmd) {
    case 'ls':
      return listDirectoryContents(currentPath, fileSystem);

    case 'cd':
      return changeDirectory(args[0], currentPath, setCurrentPath, updateFileSystem, fileSystem);

    case 'mkdir':
      return createDirectory(args[0], currentPath, updateFileSystem, fileSystem);

    case 'rmdir':
      return removeDirectory(args[0], currentPath, updateFileSystem, fileSystem);
    
    case 'ip':
        return `Your IP Address is: ${ip}`;
    case 'clear':
      setOutput(['Welcome to the Terminal!']);
      setCurrentPath('/');
      return '';

    default:
      return `Command '${cmd}' not found or not implemented.`;
  }
};




const listDirectoryContents = (path, fileSystem) => {
  const dir = getDirectory(path, fileSystem);
  if (dir) {
    return Object.keys(dir.contents).map(item => {
      const isDirectory = dir.contents[item].type === 'directory';
      const color = isDirectory ? '#423ce8' : '#3ddb62';
      return `<span style="color:${color}">${item}</span>`;
    }).join(' ');
  }
  return 'No such directory';
};

const changeDirectory = (path, currentPath, setCurrentPath, updateFileSystem, fileSystem) => {
  if (!path) {
    return `cd: missing directory. Usage: cd <directory>`;
  }

  if (path === '..') {
    const newPath = currentPath.split('/').slice(0, -2).join('/');
    setCurrentPath(newPath ? `/${newPath}/` : '/');
    return '';
  }

  const newPath = currentPath === '/' ? `/${path}/` : `${currentPath}${path}/`;
  const dir = getDirectory(currentPath, fileSystem);
  if (dir && dir.contents[path] && dir.contents[path].type === 'directory') {
    setCurrentPath(newPath);
    return '';
  }
  return `cd: no such file or directory: ${path}`;
};

const createDirectory = (dirName, currentPath, updateFileSystem, fileSystem) => {
  if (!dirName) {
    return `mkdir: missing directory name. Usage: mkdir <directory>`;
  }
  if (currentPath === '/') {
    return `You can't create directory '${dirName}' at root`;
  }
  // For non-root directories, use the standard logic
  const dir = getDirectory(currentPath, fileSystem);
  if (dir && dir.contents[dirName]) {
    return `mkdir: directory already exists: ${dirName}`;
  }

  if (dir) {
    updateFileSystem(currentPath, (currentDir) => {
      // Debug: Log current directory and update attempt
      console.log('Current Directory:', currentDir);
      console.log('Attempting to add directory:', dirName);

      return {
        ...currentDir,
        contents: {
          ...currentDir.contents,
          [dirName]: {
            type: 'directory',
            contents: {}
          }
        }
      };
    });

    return `Directory '${dirName}' created`;
  }
  
  return `mkdir: no such directory: ${currentPath}`;
};



// Removes a directory
const removeDirectory = (dirName, currentPath, updateFileSystem, fileSystem) => {
  if (!dirName) {
    return `rmdir: missing directory name. Usage: rmdir <directory>`;
  }

  const dir = getDirectory(currentPath, fileSystem);
  if (dir && dir.contents[dirName]) {
    if (dir.contents[dirName].type === 'directory' && Object.keys(dir.contents[dirName].contents).length === 0) {
      updateFileSystem(currentPath, (currentDir) => {
        // Create a copy of the current directory's contents without the directory to be removed
        const updatedContents = { ...currentDir.contents };
        delete updatedContents[dirName]; // Remove the directory

        return {
          ...currentDir,
          contents: updatedContents
        };
      });
      return `Directory '${dirName}' removed`;
    } else if (dir.contents[dirName].type === 'directory') {
      return `rmdir: directory not empty: ${dirName}`;
    } else {
      return `rmdir: not a directory: ${dirName}`;
    }
  }
  return `rmdir: no such directory: ${dirName}`;
};


// Retrieves the directory object for a given path
const getDirectory = (path, fileSystem) => {
  const parts = path.split('/').filter(Boolean);
  let dir = fileSystem['/'];

  for (const part of parts) {
    if (dir.contents[part] && dir.contents[part].type === 'directory') {
      dir = dir.contents[part];
    } else {
      return null;
    }
  }

  return dir;
};


 