import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApplicationsProvider } from "./Contexts/ApplicationsProvider";
import { GamesProvider } from "./Contexts/GamesProvider";
import { FileSystemProvider } from "./Contexts/FileSystemProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
        <ApplicationsProvider>
            <FileSystemProvider>
                    <GamesProvider>
                          <BrowserRouter>

                                  <App />

                                  
                          </BrowserRouter>
                    </GamesProvider>
              </FileSystemProvider>,
        </ApplicationsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
