import React from 'react';
import './Styles/style.css'; 
import {profileData, aboutMeData}from '../../../Data/AboutMeData';



function AboutMeContent({ isMobile }) {
  return (
<div className="scroll-container "> 
<div className='text-center  '>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1.5em' }}>
          <img
            src={profileData.imageSrc}
            alt="Profile"
            style={{ borderRadius: '50%', width: '150px', height: '150px' }}
          />
        </div>

        <h2>My name is <span style={{ color: 'white', fontWeight: '600' }}>{profileData.name}</span></h2>
        <h2>I'm a <span style={{ color: 'red', fontWeight: '600' }}>{profileData.title}</span></h2>
      </div>

      <div
        style={{
          marginLeft: isMobile ? '0%' : '10%',
          marginRight: isMobile ? '0%' : '10%',
        }}
        className='mt-5'
      >
        <ul>
          {aboutMeData.map(({ id, icon, text, email }) => (
            <li key={id} className='my-4'>
              {icon} {text}
              {email && (
                <>
                  {' '}
                  <a href={`mailto:${email}`}><u>{email}</u></a> :)
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className={`mb-${isMobile ? '32' : '10'}`} />


    </div>
  );
}

export default AboutMeContent;
