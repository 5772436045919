import React from 'react';
import './Styles/style.css'; 
import { Badge } from "flowbite-react";
import {projects}from '../../../Data/AboutMeData';


function ProjectsContent({ isMobile }) {
  return (
    <div className="scroll-container">
      <div
        style={{
          marginLeft: isMobile ? '0%' : '10%',
          marginRight: isMobile ? '0%' : '10%',
        }}
        className='mt-5'
      >
<ul>
  {projects.slice().reverse().map((project, index, array) => (
    <li key={project.id}    >
      <h1 style={{ fontSize: '20px' }} className='mb-2'>
        {project.icon} <span style={{ fontWeight: '800' }}>{project.projectType}</span> <small>({project.role})</small>
      </h1>
      <p style={{ fontSize: '15px' }}>{project.description}</p>
      <div className='mt-2'>
        {project.skills.map((skill, index) => (
    
          <Badge key={index} color="info" size="sm" class="inline-flex items-center  px-2 py-1 text-xs font-medium ring-1 ring-inset ring-gray-500/10 mr-2">
          {skill}
          </Badge>
        ))}
      </div>
      {index < array.length - 1 && <hr className='projectHr mt-9'/>}
    </li>
  ))}
</ul>


      </div>
      <div className={`mb-${isMobile ? '32' : '10'}`} />


    </div>
  );
}

export default ProjectsContent;
