import React from 'react';
import skillsData from '../../../Data/skillsData';
import './Styles/style.css'; 

function SkillsContent({ isMobile }) {
  return (
    <div className="scroll-container">
      <h2 className='text-center'>
        <span style={{ color: 'white', fontWeight: '600', fontSize: '23px' }}>Technical Skills</span>
      </h2>

      {Object.entries(skillsData).map(([category, skills]) => (
        <div key={category} className='mt-5'>
          <h3 style={{ color: 'white', fontWeight: '600', fontSize: '20px' }}>{category}  </h3>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
              justifyContent: 'center',
              marginLeft: isMobile ? '0%' : '10%',
              marginRight: isMobile ? '0%' : '10%',
            }}
          >
            {skills.map(({ id, SkillName, SkillLogo }) => (
              <div key={id} style={{ textAlign: 'center', marginTop: '1.2em' }}>
                <img
                  src={SkillLogo}
                  alt={SkillName}
                  style={{ borderRadius: '50%', width: '60px', height: '60px' }}
                />
                <p style={{ color: 'white', marginTop: '8px' }}>{SkillName}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
<div className={`mb-${isMobile ? '32' : '10'}`} />



    </div>
  );
}

export default SkillsContent;
