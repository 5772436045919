import React, {  useCallback,useState, useEffect } from 'react';
import Application from '../../Applications/Application';


function HomeMenu({
    setShowUbuntuMenu,
    ShowUbuntuMenu,
    updateApplicationActiveState,
    handleWindowClick,
    filteredApplications,
    searchTerm,
    setSearchTerm,
    windows,
    setWindows,
    formatTitle
  }) {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [isMobile] = useState(window.innerWidth <= 768);
    const [fadeClass] = useState('');
    const handleKeyPress = useCallback((event) => {
        switch (event.key) {
          case 'a': 
          console.log(event.key)
          break;
          default:
        console.log(event.key)
    
        }
    }, []);
    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        return () => {
          document.removeEventListener("keydown", handleKeyPress);
    };
    });
 
    return (
        <div
            className={fadeClass} 
            style={{
                position: "relative",
                height: "100vh", 
                width: "100vw",  
                overflow: "hidden", 
                display: "flex",
                flexDirection: "column",  
            }}>
            <div
                style={{
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: "url(https://149366088.v2.pressablecdn.com/wp-content/uploads/2022/03/jammy-jellyfish-wallpaper.jpg)",
                    backgroundSize: "cover", 
                    backgroundRepeat: "no-repeat",  
                    backgroundPosition: "center",  
                    filter: "blur(5px)",  
                    zIndex: -1,  
                }} ></div>

            <div style={{ 
                position: "relative",  
                zIndex: 1, 
                margin: !isMobile ? '0em 7em 1em 7em' : '0em 0.7em 1em 0.7em'
            }} className="py-20 " >
              
         
                      
                        <input 
                            type='text'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search applications..." 
                            className='placeholder-white placeholder-opacity-50'
                            style={{
                                backgroundColor:'#dedede44',
                                borderRadius:'0.8em',
                                border:'none',
                                placeholder:'white',
                                color:'white',
                                width:'20em',
                                height:'2.5em',
                                fontSize:'14px',
                                marginBottom:'2.5em',  
                            }}
                        />
                
        
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start'
                }}>
                    {filteredApplications?.map((app, index) => (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                padding: '11px',
                            }}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                            onClick={() => {
                                const newWindowId = `window-${index}`;

                                updateApplicationActiveState(app.AppUID, true);

                                setWindows((prevWindows) => ({
                                    ...prevWindows,
                                    [newWindowId]: { x: 100, y: 100 },  
                                }));
                                handleWindowClick(newWindowId); 
                                setShowUbuntuMenu(false);
                            }}>

                            <div
                                style={{
                                    backgroundColor: hoveredIndex === index ? 'rgba(255, 255, 255, 0.3)' : 'transparent',
                                    padding: '0.2em',
                                    borderRadius: '5px',
                                    transition: 'background-color 0.3s ease',
                                    margin:'1em',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',  
                                    justifyContent: 'center',  
                                }}
                            >
                                <Application
                                    imgUrl={app.imgUrl}
                                    isActive={app.isActive}
                                    Size={!isMobile ? 60 : 40}
                                />
                                <h4 style={{ whiteSpace: 'pre-wrap', fontSize: !isMobile ? '16px' : '14px', color: 'white', textAlign: 'center', marginTop: '0.5em' }}>
                                    {formatTitle(app.title)}  
                                </h4>                   
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default HomeMenu;
