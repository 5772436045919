import React, { useState } from 'react';

const defaultUrl = 'https://digitalnatives.team/';

const Chrome = () => {
  const [tabs, setTabs] = useState([{ id: 1, url: defaultUrl, displayUrl: defaultUrl }]);
  const [activeTab, setActiveTab] = useState(1);

  const formatUrl = (inputUrl) => {
    let newUrl = inputUrl.trim();
    if (!newUrl) {
      return defaultUrl;
    }
    if (!newUrl.startsWith('http://') && !newUrl.startsWith('https://')) {
      newUrl = newUrl.startsWith('www.') ? `https://${newUrl}` : `https://www.${newUrl}`;
    } else if (newUrl.startsWith('www.')) {
      newUrl = `https://${newUrl}`;
    }
    return newUrl;
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const newUrl = formatUrl(e.target.value);
      const updatedTabs = tabs.map(tab =>
        tab.id === activeTab
          ? { ...tab, url: newUrl, displayUrl: e.target.value }
          : tab
      );
      setTabs(updatedTabs);
      e.target.blur();
    }
  };

  const handleChange = (e) => {
    setTabs(tabs.map(tab =>
      tab.id === activeTab ? { ...tab, displayUrl: e.target.value } : tab
    ));
  };

  const handleRefresh = () => {
    const updatedTabs = tabs.map(tab =>
      tab.id === activeTab ? { ...tab, url: formatUrl(tab.displayUrl) } : tab
    );
    setTabs(updatedTabs);
  };

  const addTab = () => {
    const newTabId = Math.max(...tabs.map(tab => tab.id), 0) + 1;
    setTabs([...tabs, { id: newTabId, url: defaultUrl, displayUrl: defaultUrl }]);
    setActiveTab(newTabId);
  };

  const switchTab = (id) => {
    setActiveTab(id);
  };

  const closeTab = (id) => {
    const newTabs = tabs.filter(tab => tab.id !== id);
    if (activeTab === id) {
      if (newTabs.length > 0) {
        setActiveTab(newTabs[0].id);
      } else {
        setActiveTab(null); 
      }
    }
    setTabs(newTabs);
  };

  return (
    <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', paddingTop: '8px', backgroundColor: '#fff', color: '#fff' }}>
          {tabs.map(tab => (
            <div
              onClick={() => switchTab(tab.id)}
              key={tab.id}
              style={{
                marginLeft: '0.2em',
                display: 'flex',
                alignItems: 'center',
                marginRight: '4px',
                backgroundColor: activeTab === tab.id ? '#fff' : '#fff',
                border: '1px solid gray',
                borderBottom: 'none',
                borderRadius: '6px 6px 0px 0px',
                padding: '4px 8px',
                cursor: 'pointer',
                color: '#000',
              }}
            >
              <button
                onClick={() => switchTab(tab.id)}
                style={{
                  marginRight: '0.5em',
                  marginLeft: '0.5em',
                  fontSize: '15px',
                  background: 'none',
                  border: 'none',
                  color: '#000',
                }}
              >
                Tab {tab.id}
              </button>
              <button
                onClick={() => closeTab(tab.id)}
                style={{
                  marginRight: '0.5em',
                  fontSize: '14px',
                  background: 'none',
                  border: 'none',
                  color: '#000',
                  cursor: 'pointer',
                }}
              >
                ×
              </button>
            </div>
          ))}
          <button
            onClick={addTab}
            style={{
              backgroundColor: '#fff',
              color: '#000',
              border: 'none',
              borderRadius: '4px',
              padding: '4px 8px',
              marginLeft: '8px',
              cursor: 'pointer',
            }}
          >
            +
          </button>
        </div>
    
        <div style={{ display: 'flex', alignItems: 'center', padding: '8px', backgroundColor: '#fff', color: '#000' }}>
      
        <button
  onClick={handleRefresh}
  style={{
    backgroundColor: '#fff',
    color: '#000',
    padding: '4px',
    marginRight: '8px',
    cursor: 'pointer',
    border: 'none', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    fontSize: '16px',
  }}
>
 
 <svg fill="#000000"  width="16px" height="16px" viewBox="0 0 24 24" id="left-arrow" data-name="Flat Color" xmlns="http://www.w3.org/2000/svg" class="icon flat-color"><path id="primary" d="M21,11H5.41l1.3-1.29A1,1,0,0,0,5.29,8.29l-3,3a1,1,0,0,0,0,1.42l3,3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L5.41,13H21a1,1,0,0,0,0-2Z"  ></path></svg>
</button>
<button

  onClick={handleRefresh}
  style={{
    backgroundColor: '#fff',
    color: '#000',
    padding: '4px',
    marginRight: '8px',
    cursor: 'pointer',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    
  }}
>

  
<svg  fill="#C8C8C8"  width="16px" height="16px" viewBox="0 0 24 24" id="left-arrow" data-name="Flat Color" xmlns="http://www.w3.org/2000/svg" class="icon flat-color"><path id="primary" d="M21.71,11.29l-3-3a1,1,0,0,0-1.42,1.42L18.59,11H3a1,1,0,0,0,0,2H18.59l-1.3,1.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l3-3A1,1,0,0,0,21.71,11.29Z"  ></path></svg>
</button>
<button
  onClick={handleRefresh}
  style={{
    backgroundColor: '#fff',
    color: '#000',
    padding: '4px',
    marginRight: '8px',
    cursor: 'pointer',
    border: 'none',
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    fontSize: '16px',
  }}
>
 

  <svg   width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 3V8M21 8H16M21 8L18 5.29168C16.4077 3.86656 14.3051 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.2832 21 19.8675 18.008 20.777 14" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</button>

          <input
            type="text"
            value={tabs.find(tab => tab.id === activeTab)?.displayUrl || ''}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            style={{ flexGrow: 1, padding: '4px', backgroundColor: '#fff', color: '#000', borderWidth: '1px', borderColor: 'gray', borderRadius: '4px' }}
          />
        </div>
      </div>
      <iframe
        src={tabs.find(tab => tab.id === activeTab)?.url || ''}
        style={{ flexGrow: 1, border: 'none' }}
        title="Chrome-like Browser"
      />
    </div>
  );
};

export default Chrome;
