 
const profileData = {
    name: 'Anis Ben Bechir',
    title: 'Full stack Developer',
    email: 'anisbenbechir.proline@gmail.com',
    imageSrc: 'https://media.licdn.com/dms/image/v2/D4E03AQGSD2KA3RIOrw/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1677855565228?e=1729123200&v=beta&t=7PiLG596Icnuyvi7HC23xE66FL6nCnF4_k9wLTGcKSc',
  };
  
const aboutMeData = [
    {
      id: 1,
      icon: '🏰',
      text: `I'm a Graduate Student currently studying Computer Science. I began programming at the young age of 14, and now I'm a full-stack developer. I'm currently seeking full-time opportunities as a Developer! Feel free to reach out at`,
      email: profileData.email,
    },
    {
      id: 2,
      icon: '🎯',
      text: `I’m passionate about creating innovative software solutions that address real-world challenges.`,
    },
    {
      id: 3,
      icon: '♟',
      text: `When I’m not coding, I love immersing myself in flight simulation, playing video games, or going fishing.`,
    },
];


const educationData = [
    {
      id: 1,
      institution: 'Central University',
      degree: "Bachelor's degree in Computer Science in Software Engineering and Information Systems",
      icon: '👨‍🎓',
    },
    {
      id: 2,
      institution: 'Les Berges du Lac 2 High School',
      degree: 'High School degree in Experimental Science',
      icon: '📖',
    },
  ];
  const BusinessAdministration = [
    {
      id: 1,
      Subject: 'Leadership',
      Professor: 'Mohamed Ali Belajouza',
      icon: '🧑‍✈️',
    },
    {
      id: 2,
      Subject: 'Design Thinking',
      Professor: 'Zeineb Ben azouz',
      icon: '🕸️',
    },
    {
      id: 3,
      Subject: 'Digital Marketing',
      Professor: 'Nour Bouakline',
      icon: '🔊',
    },
    
    {
      id: 4,
      Subject: 'Sales and Negotiation',
      Professor: 'Chahinez Ben Amor',
      icon: '🤝',
    },
    {
      id: 5,
      Subject: 'Communication',
      Professor: 'Jawher Chtiou',
      icon: '🗣️',
    },
    {
      id: 6,
      Subject: 'Legislation in Tunisia for businesses/companies',
      Professor: 'Hatem Louati',
      icon: '⚖️',
    },
    {
      id: 7,
      Subject: 'Financing ',
      Professor: 'Mouadh Mhiri',
      icon: '💰',
    },
    {
      id: 8,
      Subject: 'Human Resources Management',
      Professor: 'Selima Ben Ammar',
      icon: '🤼',
    },
  ];
  
  const coursesAndTrainings = [
    {
      id: 1,
      courseName: 'Agile Project Management',
      provider: 'Coursera - Google',
      icon: '🔂',
    },
    {
      id: 2,
      courseName: 'Data Science',
      provider: 'Coursera - IBM',
      icon: '📊',
    },
    {
      id: 3,
      courseName: 'Ethical Hacking: Penetration Testing and Web Security',
      provider: 'Udemy - Cyberini',
      icon: '✨',
    },
  ];
  
const projects = [

    {
      id: 1,
      projectType: 'Mobile Application',
      role: 'Full-stack Developer',
      description: `Engineered a comprehensive mobile application for the Warmth NPO using Angular and Ionic, focusing on optimizing operations and member engagement. The app includes a dispatch system for activities, a real-time voting mechanism, a transparent finance tracking feature, a scheduling module, and a member directory to streamline communication. Leveraged Firebase for dynamic data handling and Bootstrap for an intuitive user interface.`,
      skills: ['Angular', 'Ionic', 'Firebase Database', 'Bootstrap UI', 'Real-time Features'],
      icon: '📱',
    },  {
      id: 2,
      projectType: 'WordPress Website',
      role: 'Full-stack Developer',
      description: `Led the development of a feature-rich WordPress website for "Warmth for Charity," an NPO, within a cPanel environment. Transformed an HTML theme into a fully functional WordPress site. Implemented robust role-based access control, fortified the site with the Wordfence security plugin, and enhanced security by concealing the login page and wp-content folder. Integrated custom contact forms linked to the NPO's SMTP server for seamless communication.`,
      skills: ['WordPress', 'cPanel', 'HTML to WordPress Conversion', 'Security (Wordfence)', 'SMTP Integration'],
      icon: '🌐',
    },
    {
      id: 3,
      projectType: 'University Website',
      role: 'Full-stack Developer',
      description: `Developed a dynamic and visually appealing static website for Esat University, accessible at https://esat.ens.tn. The site effectively showcases the university's programs and activities through a sleek Bootstrap design. Additionally, implemented interactive contact and registration forms, utilizing PHPMailer for reliable email communication.`,
      skills: ['PHP', 'Bootstrap Framework', 'PHPMailer Integration', 'Dynamic Form Handling', 'UI/UX Design'],
      icon: '🏫',
    },
    {
      id: 4,
      projectType: 'Real Estate Listing Website',
      role: 'Full-stack Developer',
      description: `Built a powerful and scalable real estate listing platform using PHP, Express.js, and SQL, tailored for real estate professionals. The platform includes advanced property management features, lead management, an invoicing system, payment gateways, a recruitment module, and generative AI tools to enhance user engagement and provide personalized property rankings based on user activities.`,
      skills: ['PHP', 'Express.js', 'SQL Database', 'Payment Integration', 'AI Tools', 'Property Management Systems'],
      icon: '🏠',
    },
    {
      id: 5,
      projectType: 'Company Website',
      role: 'Full-stack Developer',
      description: `Developed a cutting-edge website for "Digital Natives," accessible at https://digitalnatives.team/, utilizing Next.js and Express.js. The site features detailed service offerings, integrated customer acquisition funnels, and CRM systems for automated tracking (HubSpot). Additionally, it includes a dedicated panel for company ambassadors, enabling transaction tracking and payroll management.`,
      skills: ['Next.js', 'Express.js', 'HubSpot CRM', 'Customer Acquisition', 'Automation Systems', 'Payroll Management'],
      icon: '💼',
    },
    {
      id: 6,
      projectType: 'Airbnb-like Web Application',
      role: 'Project Manager',
      description: `Spearheaded a team of developers to create a sophisticated Airbnb-like web application in React.js and Express.js, available at https://superhost.com.tn/. The platform features comprehensive property management, a streamlined short-term rental system, foreign booking synchronization via cron jobs, real-time activity tracking with Socket.IO, and an advanced filtering system that intelligently sorts properties based on user behavior and performance analytics.`,
      skills: ['React.js', 'Express.js', 'Socket.IO', 'Cron Jobs', 'Advanced Filtering Systems', 'Team Leadership'],
      icon: '🏡',
    },

  ];
export  {
    profileData, 
    aboutMeData , 
    educationData, 
    BusinessAdministration , 
    coursesAndTrainings,
    projects
    
};
