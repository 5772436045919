import React, { useState, useRef, useEffect } from 'react';
import { handleCommand } from './commandUtils';
import { useFileSystem } from '../../../Contexts/FileSystemProvider'; 
import axios from 'axios';

const Terminal = () => {
  const { fileSystem, updateFileSystem } = useFileSystem();
  const [input, setInput] = useState('');
  const [ip, setIP] = useState(null);
  const [output, setOutput] = useState(['Welcome to the Terminal!']);
  const [currentPath, setCurrentPath] = useState('/');
  const inputRef = useRef(null);
  const outputEndRef = useRef(null);

  const User = "root";
  const Server = "cloud-554149";
  const terminalPrefix = `${User}@${Server}`;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      const result = handleCommand(input.trim(), currentPath, setOutput, setCurrentPath, updateFileSystem, fileSystem , ip);
      setOutput(prevOutput => [
        ...prevOutput.slice(output[0] === 'Welcome to the Terminal!' ? 1 : 0),
        `${terminalPrefix}:${currentPath}# ${input.trim()}`,
        result
      ]);
      setInput('');
    }
  };
  const fetchIP = async () => {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      setIP(response.data.ip);
    } catch (error) {
      console.error('Error fetching IP address:', error);
      setIP('Error fetching IP');
    }
  };
  useEffect(() => {
    fetchIP();

    if (outputEndRef.current) {
      outputEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [output]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleClick = () => {
    inputRef.current?.focus();
  };

  return (
    <div
      style={{
        backgroundColor: 'black',
        width: '100%',
        height: '100%',
        color: '#24f254',
        padding: '10px',
        cursor: 'text',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: '#24f254 black',
      }}
      onClick={handleClick}
    >
      {output.map((line, index) => (
        <div key={index} dangerouslySetInnerHTML={{ __html: line }} />
      ))}
     {/*   <div ref={outputEndRef} />   */}
      <form onSubmit={handleSubmit} style={{ display: 'flex' }}>
        <span>{terminalPrefix}:{currentPath}#&nbsp;</span>
        <input
          ref={inputRef}
          style={{
            backgroundColor: 'black',
            color: '#257a1d',
            border: 'none',
            outline: 'none',
            flexGrow: 1,
          }}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          autoFocus
        />
      </form>
      <style jsx>{`
        ::-webkit-scrollbar {
          width: 2px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #24f254;
          border-radius: 4px;
        }
        ::-webkit-scrollbar-track {
          background: black;
        }
      `}</style>
    </div>
  );
};

export default Terminal;