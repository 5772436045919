import React from "react";
import { MdBatteryCharging80, MdVolumeDownAlt, MdNetworkWifi } from "react-icons/md";
import { IoIosPower } from "react-icons/io";
import { GoLock } from "react-icons/go";
import { GrSettingsOption } from "react-icons/gr";
import { IoBatteryCharging, IoAirplaneOutline } from "react-icons/io5";
import { FaMicrophoneLines } from "react-icons/fa6";
import { CiBrightnessUp } from "react-icons/ci";
import { FaBluetoothB } from "react-icons/fa";
import { MdOutlineBrightnessMedium } from "react-icons/md";

import './Styles/styles.css'

const SystemMenu = ({ isMobile }) => {
  return (
    <div
      className={`absolute top-7 ${isMobile ? 'right-0 h-full' : 'right-[0%] rounded-3xl'} z-10 text-white`}
      style={{ 
        backgroundColor: '#181818ff',
        width: isMobile ? '100%' : '20%'
      }}
    >
      <div className={`m-4 ${isMobile ? 'gap-y-5' : 'gap-x-5'}`}>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center justify-center circle">
            <IoBatteryCharging className="ml-2" color="white" size={18} />
            <p className="mx-1 mr-2 text-white">79%</p>
          </div>
          <div className="flex items-center">
            <div className="flex items-center justify-center circle ml-2">
              <GrSettingsOption color="white" size={21} />
            </div>
            <div className="flex items-center justify-center circle ml-2">
              <GoLock color="white" size={21} />
            </div>
            <div className="flex items-center justify-center circle ml-2">
              <IoIosPower color="white" size={21} />
            </div>
          </div>
        </div>

        <div className="mx-4">
          <ul>
            <li>
              <div className="flex items-left justify-left my-2">
                <MdVolumeDownAlt className="ml-2" color="white" size={30} />
                <input
                  type="range"
                  className="transparent h-1.5 w-full cursor-pointer appearance-none rounded-lg border-transparent bg-gray-700 mt-3 ml-3"
                  id="customRange1"
                  value={63}
                />
              </div>
            </li>
            <li className="mt-6">
              <div className="flex items-left justify-left my-2">
                <FaMicrophoneLines className="ml-2" color="white" size={20} />
                <input
                  type="range"
                  className="transparent h-1.5 w-full cursor-pointer appearance-none rounded-lg border-transparent bg-gray-700 mt-3 ml-5"
                  id="customRange1"
                  value={100}
                />
              </div>
            </li>
            <li className="mt-6">
              <div className="flex items-left justify-left my-2">
                <CiBrightnessUp className="ml-2" color="white" size={24} />
                <input
                  type="range"
                  className="transparent h-1.5 w-full cursor-pointer appearance-none rounded-lg border-transparent bg-gray-700 mt-3 ml-5"
                  id="customRange1"
                  value={100}
                />
              </div>
            </li>
          </ul>
        </div>

        <div className={`mt-4 grid ${isMobile ? 'grid-cols-2 gap-y-5' : 'grid-cols-12 gap-x-5'}`}>
          <div className={`col-span-6 flex justify-left mt-4`}>
            <div className="flex bg-blue-500 text-gray-800 text-xs font-medium py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300" style={{ width: '100%' }}>
              <FaMicrophoneLines className="m-1" color="black" size={20} />
              <div className="ml-2">
                <h6><strong>Dark</strong></h6>
                <p>Office WIFI</p>
              </div>
            </div>
          </div>

          <div className={`col-span-6 flex justify-left mt-4`}>
            <div className="flex bg-blue-500 text-gray-800 text-s font-medium px-4 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300 h-min" style={{ width: '100%' }}>
              <FaBluetoothB className="m-1" color="black" size={22} />
              <div className="m-1">
                <h2><strong>Bluetooth</strong></h2>
              </div>
            </div>
          </div>

          <div className={`col-span-6 flex justify-left mt-4`}>
            <div className="flex bg-gray-500 text-white text-s font-medium px-4 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300 h-min" style={{ width: '100%' }}>
              <MdOutlineBrightnessMedium className="m-1" color="white" size={22} />
              <div className="m-1">
                <h2><strong>Dark Style</strong></h2>
              </div>
            </div>
          </div>

          <div className={`col-span-6 flex justify-left mt-4`}>
            <div className="flex bg-gray-500 text-white text-xs font-medium px-4 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300 h-min" style={{ width: '100%' }}>
              <IoAirplaneOutline className="m-1" color="white" size={22} />
              <div className="m-2">
                <h6><strong>Airplane Mode</strong></h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemMenu;
