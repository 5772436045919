import React, { createContext, useContext, useState } from 'react';
import VsCode from '../components/Apps/VsCode';
import Terminal from '../components/Apps/Terminal';
import Chrome from '../components/Apps/Chrome';
import AboutMe from '../components/Apps/AboutMe';
import Games from '../components/Apps/Games';


const ApplicationsContext = createContext();

export const ApplicationsProvider = ({ children }) => {
  const [applications, setApplications] = useState([
    {
      AppUID :'AboutnMe',
      title: 'About\nMe',
      imgUrl: '/images/Applications/AboutMe.webp',
      isActive: false,
      Pinned: true, 
      AppComponent: <AboutMe />,
      WindowHeight:  70 , 
      WindowWidth  : 50,
    }, 
    {
      AppUID :'GooglenChrome',
      title: 'Google\nChrome',
      imgUrl: '/images/Applications/Chrome.png',
      isActive: false,
      Pinned: true, 
      AppComponent: <Chrome />,
      WindowHeight:  85 , 
      WindowWidth  : 90,
    }, 
    {
      AppUID :'VS',
      title: ' VS\nCode',
      imgUrl: '/images/Applications/VsCode.png',

      isActive: false,
      Pinned: false,
      AppComponent: <VsCode />,
    },
    {
        AppUID :'Terminal',
        title: 'Terminal',
        imgUrl: '/images/Applications/Terminal.png',
        isActive: false,
        Pinned: true ,
        AppComponent: <Terminal />,
      },
      {
        AppUID :'Games',
        title: 'Games',
        imgUrl: '/images/Applications/Games.png',
        isActive: false,
        Pinned: false, 
        AppComponent: <Games />,
        WindowHeight:  75 , 
        WindowWidth  : 70,
      },  
      {
        AppUID :'ThreeJs',
        title: 'Three Js',
        imgUrl: '/images/Applications/ThreeJs.webp',
        isActive: false,
        Pinned: true, 
        Path:'/ThreeJs'
      }, 
  ]);

  return (
    <ApplicationsContext.Provider value={[applications, setApplications]}>
      {children}
    </ApplicationsContext.Provider>
  );
};

export const useApplications = () => useContext(ApplicationsContext);
