import React from "react";
import Calendar from 'react-calendar';

const CalendarMenu = ({ isMobile }) => {
  return (
    <div
      className={`absolute top-7 ${isMobile ? 'left-0  h-full' : 'left-[28%]  rounded-3xl'} z-10 text-white `}
      style={{ 
        backgroundColor: '#181818ff',
        width: isMobile ? '100%' : '40%'
    }}  >
      <div className={`m-5 grid ${isMobile ? 'grid-cols-1 gap-y-5' : 'grid-cols-12 gap-x-5'} `}  >



        <div className={`${isMobile ? 'col-span-12' : 'col-span-7'} flex justify-center`}>
        
          <iframe style={{ borderRadius: '12px', width: '100%', height: '50%' }} src="https://open.spotify.com/embed/track/3Fyj22NPIu3kzhpu9KF7Bg?utm_source=generator&theme=0"  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" ></iframe>
        
        </div>

        <div className={`${isMobile ? 'col-span-12' : 'col-span-5'} flex flex-col  m-1`}>
          <Calendar className="text-center text-gray-400 text-opacity-90" />
          
        
          <div   style={{    backgroundColor:'#282828' }}  className="mt-2 p-2  rounded-xl" >
            <div className="my-1 mx-2">
            <h3 className="font-bold">Today</h3>
            <p style={{fontWeight:500, fontSize:'12px', color:'gray' }} > No Events</p>
            
            </div>
          </div>


          <div style={{  backgroundColor:'#282828' }}  className="mt-2 p-2  rounded-xl" >
            <div className="my-1/2 mx-2">
            <p style={{fontWeight:700, fontSize:'12px', color:'gray' }} >Add world clocks...</p>
            </div>
          </div>
        </div>


        
      </div>
    </div>
  );
};

export default CalendarMenu;
