const skillsData = {
  'Programming Languages': [
    {
      id: 1,
      SkillName: 'JavaScript',
      SkillLogo: "/images/Skills/JavaScript.png",
    },
    {
      id: 2,
      SkillName: 'TypeScript',
      SkillLogo: "/images/Skills/TypeScript.png",
    },
    {
      id: 3,
      SkillName: 'PHP',
      SkillLogo: "/images/Skills/PHP.png",
    }, 
    {
      id: 4,
      SkillName: 'Python',
      SkillLogo: "/images/Skills/Python.png",
    }, 
    {
      id: 5,
      SkillName: 'HTML',
      SkillLogo: "/images/Skills/HTML.png",
    },
    {
      id: 6,
      SkillName: 'CSS',
      SkillLogo: "/images/Skills/CSS.png",
    },
    {
      id: 7,
      SkillName: 'C',
      SkillLogo: "/images/Skills/C.png",
    },
    {
      id: 8,
      SkillName: 'C++',
      SkillLogo: "/images/Skills/C++.png",
    },
  
  ],
  'Front-End & Back-End Frameworks': [
    {
      id: 1,
      SkillName: 'Angular',
      SkillLogo: "/images/Skills/Angular.png",
    },
    {
      id: 2,
      SkillName: 'React',
      SkillLogo: "/images/Skills/React.png",
    },
    {
      id: 3,
      SkillName: 'Express.js',
      SkillLogo: "/images/Skills/Express.js.png",
    },
  ],
  'Hybrid Frameworks': [
    {
      id: 1,
      SkillName: 'Ionic',
      SkillLogo: "/images/Skills/Ionic.png",
    },
    {
      id: 2,
      SkillName: 'Electron',
      SkillLogo: "/images/Skills/Electron.png",
    },
  ],
  'Database & CDN': [
    {
      id: 1,
      SkillName: 'MySQL',
      SkillLogo: "/images/Skills/MySQL.png",
    }, 
    {
      id: 2,
      SkillName: 'Firebase',
      SkillLogo: "/images/Skills/Firebase.png",
    }, 
    {
      id: 3,
      SkillName: 'Supabase',
      SkillLogo: "/images/Skills/Supabase.png",
    }, 
    {
      id: 4,
      SkillName: 'Cloudflare',
      SkillLogo: "/images/Skills/Cloudflare.png",
    },
  ],
  'Deployment & DevOps': [
    {
      id: 1,
      SkillName: 'Ubuntu',
      SkillLogo: "/images/Skills/Ubuntu.png",
    },
    {
      id: 2,
      SkillName: 'Apache2',
      SkillLogo: "/images/Skills/Apache2.png",
    },
    {
      id: 3,
      SkillName: 'SQL Server',
      SkillLogo: "/images/Skills/SQL Server.png",
    }, 
    {
      id: 4,
      SkillName: 'PM2',
      SkillLogo: "/images/Skills/PM2.png",
    },
    {
      id: 5,
      SkillName: 'CPanel',
      SkillLogo: "/images/Skills/CPanel.png",
    }, 
    {
      id: 6,
      SkillName: 'Plesk',
      SkillLogo: "/images/Skills/Plesk.png",
    }, 
    {
      id: 7,
      SkillName: 'FastPanel',
      SkillLogo: "/images/Skills/FastPanel.png",
    }, 
    {
      id: 8,
      SkillName: 'GitHub',
      SkillLogo: "/images/Skills/GitHub.png",
    },
  ],
  'Content Management Systems': [
    {
      id: 1,
      SkillName: 'WordPress',
      SkillLogo: "/images/Skills/WordPress.png",
    },
    {
      id: 2,
      SkillName: 'PrestaShop',
      SkillLogo: "/images/Skills/PrestaShop.png",
    }, 
    {
      id: 3,
      SkillName: 'Shopify',
      SkillLogo: "/images/Skills/Shopify.png",
    }, 
    {
      id: 4,
      SkillName: 'MyBB',
      SkillLogo: "/images/Skills/MyBB.png",
    }, 
  ],
  'Security': [
    {
      id: 1,
      SkillName: 'Kali Linux',
      SkillLogo: "/images/Skills/Kali Linux.png",
    },
    {
      id: 2,
      SkillName: 'Metasploit',
      SkillLogo: "/images/Skills/Metasploit.png",
    },
    {
      id: 3,
      SkillName: 'SQLMap',
      SkillLogo: "/images/Skills/SQLMap.png",
    }, 
    {
      id: 4,
      SkillName: 'Nmap',
      SkillLogo: "/images/Skills/Nmap.png",
    },
    {
      id: 5,
      SkillName: 'Wireshark',
      SkillLogo: "/images/Skills/Wireshark.png",
    },
  ],
  'Divers': [
    {
      id: 1,
      SkillName: 'Sequelize',
      SkillLogo: "/images/Skills/Sequelize.png",
    }, 
    {
      id: 2,
      SkillName: 'JWT',
      SkillLogo: "/images/Skills/JWT.png",
    }, 
    {
      id: 3,
      SkillName: 'Termius',
      SkillLogo: "/images/Skills/Termius.png",
    }, 
    {
      id: 4,
      SkillName: 'PolyPane',
      SkillLogo: "/images/Skills/PolyPane.png",
    }, 
  ],
};

export default skillsData;
