import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Window({
  title,
  initialPosition,
  onPositionChange,
  onClose,
  onMaximize,
  zIndex,
  onClick,
  AppIcon,
  WindowHeight,
  WindowWidth,
  App, 
  AppUID,
  setShowLeftBar,
  Path
}) {
  const [position, setPosition] = useState(initialPosition);
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [isMaximized, setIsMaximized] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  const handleMouseDown = (e) => {
    setDragOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const newPosition = {
        x: e.clientX - dragOffset.x,
        y: e.clientY - dragOffset.y,
      };
      setPosition(newPosition);
      if (onPositionChange) {
        onPositionChange(newPosition);
      }
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMaximize = () => {
    if (isMaximized) {
      setPosition({ x: 100, y: 100 });
      setIsMaximized(false);
      if (onMaximize) {
        onMaximize({ x: 100, y: 100 });
      }
    } else {
      setPosition({
        x: 0,
        y: 0,
      });
      setIsMaximized(true);
      if (onMaximize) {
        onMaximize({
          x: 0,
          y: 0,
        });
      }
    }
  };
 
  const handleClose = () => {

 
    if (onClose) {
      setShowLeftBar(true)
       onClose();
    }
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize(); 
    if (isMobile) {
      setIsMaximized(true);
    }

    if (Path && Path !== '/') {
      navigate(Path); 
    }

    setPosition(initialPosition);
  }, [initialPosition, Path, isMobile, navigate]);

  
  return (
    <div
      style={{
        position: 'absolute',
        top: isMaximized ? 0 : position.y,
        left: isMaximized ? 0 : position.x,
        width:  isMaximized ? '100%' : `${WindowWidth}%`,
        height: isMaximized ? '100%' : `${WindowHeight}%`,
        marginLeft: isMaximized ? '0em' : '0em',
        marginRight: isMaximized ? '1em' : '0em',
        backgroundColor: '#2c2c2c',
        cursor: isDragging ? 'grabbing' : 'move',
        borderRadius: '5px',
        userSelect: 'none',
        transition: 'width 0.3s ease, height 0.3s ease',
        zIndex: zIndex,
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onClick={onClick}
    >
     <div
  style={{
    width: '100%',
    height: isMobile ? '50px' : '42px',
    backgroundColor: '#1c1c1c',
    cursor: 'move',
    borderTopLeftRadius: isMaximized ? '0px' : '5px',
    borderTopRightRadius: isMaximized ? '0px' : '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px',
    color: 'white',
    marginTop: (isMobile || isMaximized) ? '1.8em' : '0em', 
    position: (isMobile || isMaximized) ? 'fixed' : 'relative',  
    top: (isMobile || isMaximized) ? '0' : 'auto',  
    left: 0,  
    zIndex: 1000,
  }}
>
  <div className='text-left'>
    <img src={AppIcon} style={{ height: '19px' }} alt={`${title} icon`} />
  </div>
  <span style={{ fontWeight: 'bold' }}>{title}</span>

  <div style={{ marginRight: '0.6em'  }}>
    {!isMobile ? (
      <div>
        <button
          style={{
            background: 'none',
            border: 'none',
            color: 'white',
            fontSize: '18px',
            cursor: 'pointer',
            marginLeft: '10px',
          }}
          onClick={handleMaximize}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <path fill="#fff" d="M4 10v1h8v-1z" />
          </svg>
        </button>

        {isMaximized ? (
          <button
            style={{
              background: 'none',
              border: 'none',
              color: 'white',
              fontSize: '18px',
              cursor: 'pointer',
              marginLeft: '10px',
            }}
            onClick={handleMaximize}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
              <g fill="#fff">
                <path d="M4 6v6h6V6zm1 1h4v4H5z" />
                <path d="M6 4v1h5v5h1V4z" opacity=".5" />
              </g>
            </svg>
          </button>
        ) : (
          <button
            style={{
              background: 'none',
              border: 'none',
              color: 'white',
              fontSize: '18px',
              cursor: 'pointer',
              marginLeft: '10px',
            }}
            onClick={handleMaximize}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
              <path fill="#fff" d="M4 4v8h8V4zm1 1h6v6H5z" />
            </svg>
          </button>
        )}

        <button
          style={{
            background: 'none',
            border: 'none',
            color: 'white',
            fontSize: '18px',
            cursor: 'pointer',
            marginLeft: '10px',
          }}
          onClick={handleClose}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <path
              fill="#fff"
              fillRule="evenodd"
              d="M4.795 3.912l-.883.883.147.146L7.117 8 4.06 11.059l-.147.146.883.883.146-.147L8 8.883l3.059 3.058.146.147.883-.883-.147-.146L8.883 8l3.058-3.059.147-.146-.883-.883-.146.147L8 7.117 4.941 4.06z"
            />
          </svg>
        </button>
      </div>
    ) : (
      <button
        style={{
          background: 'none',
          border: 'none',
          color: 'white',
          fontSize: '18px',
          cursor: 'pointer',
          marginLeft: '10px',
        }}
        onClick={handleClose}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M4.795 3.912l-.883.883.147.146L7.117 8 4.06 11.059l-.147.146.883.883.146-.147L8 8.883l3.059 3.058.146.147.883-.883-.147-.146L8.883 8l3.058-3.059.147-.146-.883-.883-.146.147L8 7.117 4.941 4.06z"
          />
        </svg>
      </button>
    )}
  </div>
</div>


      <div style={{ height: '95.8%', marginBottom: '0.2em' , marginTop: isMaximized ? '4.3em' : '0em' }}>
        {App}
      </div>
    </div>
  );
}

export default Window;
