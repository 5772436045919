import React, { createContext, useState, useContext } from 'react';

// Initial file system state
const initialFileSystem = {
  '/': {
    type: 'directory',
    contents: {
      'home': {
        type: 'directory',
        contents: {
          'user': {
            type: 'directory',
            contents: {
              'documents': { type: 'directory', contents: {} },
              'photos': { type: 'directory', contents: {} },
            }
          },
          'file.txt': {
            type: 'file',
            contents: 'This is a text file.'
          }
        }
      },
      'etc': {
        type: 'directory',
        contents: {},
      },
      'var': {
        type: 'directory',
        contents: {},
      },
      'file.txt': {
        type: 'file',
        contents: 'This is a text file.'
      }
    }
  }
};

// Create context
const FileSystemContext = createContext();

// Create provider component
export const FileSystemProvider = ({ children }) => {
  const [fileSystem, setFileSystem] = useState(initialFileSystem);

  // Function to update the file system
  const updateFileSystem = (path, updateFn) => {
    setFileSystem(prevFileSystem => {
      const newFileSystem = { ...prevFileSystem };
      const parts = path.split('/').filter(Boolean);
      let dir = newFileSystem['/'];

      // Navigate to the target directory
      for (let i = 0; i < parts.length - 1; i++) {
        if (dir.contents[parts[i]] && dir.contents[parts[i]].type === 'directory') {
          dir = dir.contents[parts[i]];
        } else {
          return prevFileSystem; // Path does not exist
        }
      }

      // Update the target directory or file
      const lastPart = parts[parts.length - 1];
      if (dir.contents[lastPart]) {
        dir.contents[lastPart] = updateFn(dir.contents[lastPart]);
      }

      return newFileSystem;
    });
  };

  return (
    <FileSystemContext.Provider value={{ fileSystem, updateFileSystem }}>
      {children}
    </FileSystemContext.Provider>
  );
};

// Custom hook to use the file system context
export const useFileSystem = () => {
  const context = useContext(FileSystemContext);
  if (!context) {
    throw new Error('useFileSystem must be used within a FileSystemProvider');
  }
  return context;
};
