import React, { useState, useEffect } from "react";
import {FaUser} from "react-icons/fa";
import { IoChevronDownOutline } from "react-icons/io5";
import GamesLibrary from "./GamesLibrary";
import { GiBrain } from "react-icons/gi";
import { CgCardSpades } from "react-icons/cg";

import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";

function Games() {
  const [selectedSection, setSelectedSection] = useState("classicgames");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const CheckDevice = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", CheckDevice);
    return () => window.removeEventListener("resize", CheckDevice);
  }, []);

  const sections = {
    classicgames: {
      label: "Classic Games",
      icon: <FaUser />,
      component: <GamesLibrary isMobile={isMobile} GameType={'Classic'} />,
    },
    cardgames: {
      label: "Card Games",
      icon: <CgCardSpades />,
      component: <GamesLibrary isMobile={isMobile} GameType={'CardGames'} />,
    },
    mindgames: {
      label: "Mind games",
      icon: <GiBrain />,
      component: <GamesLibrary isMobile={isMobile} GameType={'MindGames'} />,
    }  
  };

  // Handle undefined sections[selectedSection] case
  const currentSection = sections[selectedSection] || {};

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        backgroundColor: '#111111',
      }}
    >
      {!isMobile && (
        <div
          style={{
            width: "30%",
            maxWidth: '200px',
            backgroundColor: "#333333",
            color: "white",
            padding: "1em",
          }}
        >
          <ul>
            {Object.keys(sections).map((key) => (
              <li
                key={key}
                onClick={() => setSelectedSection(key)}
                style={{
                  cursor: "pointer",
                  backgroundColor:
                  selectedSection === key ? "#e95420" : "transparent",
                  padding: "0.5em",
                  borderRadius: "4px",
                  marginBottom: "0.5em",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ marginRight: "0.5em" }}>
                  {sections[key].icon}
                </span>
                {sections[key].label}
              </li>
            ))}
          </ul>
        </div>
      )}

      <div
        style={{
          width:  "100%" ,
          backgroundColor: "#111111",
          padding: "1em",
          color: "white",
        }}
      >
        {isMobile && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1.2em",
            }}
          >
            <p style={{ margin: 0 }}>{currentSection.label}</p>
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <MenuButton className="inline-flex justify-center gap-x-1.5 rounded-md px-1 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  <IoChevronDownOutline
                    aria-hidden="true"
                    className="h-5 w-5 text-gray-400"
                  />
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                style={{ backgroundColor: "#1c1c1c" }}
              >
                <div className="py-1">
                  {Object.keys(sections).map((key) => (
                    <MenuItem key={key}>
                      <a
                        onClick={() => setSelectedSection(key)}
                        className="block px-4 py-2 text-sm text-white-700 data-[focus]:bg-white-100 data-[focus]:text-white-900"
                      >
                        {sections[key].label}
                      </a>
                    </MenuItem>
                  ))}
                </div>
              </MenuItems>
            </Menu>
          </div>
        )}

        {currentSection.component}
      </div>
    </div>
  );
}

export default Games;
